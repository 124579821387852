import * as React from "react";

export default function UndrawHomeScreen({ className }) {
  return (
    <svg
      id="acf6401d-94a8-428e-9730-6b41bf40021d"
      data-name="Layer 1"
      version="1.1"
      viewBox="0 0 489.11 694.4"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden="true"
    >
      <path
        d="m349.82 160.26v78.691a2.751 2.751 0 0 1-2.7436 2.7437h-1.9528v409.93a42.775 42.775 0 0 1-42.772 42.772h-256.14a42.764 42.764 0 0 1-42.76-42.768v-395.56h-1.4138a1.938 1.938 0 0 1-1.9409-1.929v-44.785a1.9406 1.9406 0 0 1 1.9402-1.941h1.4146v-16.869h-1.4258a2.0325 2.0325 0 0 1-2.0247-2.0368v-44.174a2.03 2.03 0 0 1 2.0247-2.0248h1.4258v-23.028h-1.4498a1.7948 1.7948 0 0 1-1.7972-1.7924v-24.111a1.805 1.805 0 0 1 1.7972-1.8091h1.4498v-48.811a42.762 42.762 0 0 1 42.76-42.76h256.14a42.772 42.772 0 0 1 42.772 42.76v114.75h1.9528a2.7509 2.7509 0 0 1 2.7437 2.7436z"
        fill="#3f3d56"
      />
      <path
        d="m145 21.933h41.622a3.0454 3.0454 0 0 1 3.0454 3.0454v3.5975a3.0454 3.0454 0 0 1-3.0454 3.0454h-41.622a3.0454 3.0454 0 0 1-3.0454-3.0454v-3.5975a3.0454 3.0454 0 0 1 3.0454-3.0454z"
        fill="#e6e8ec"
      />
      <circle cx="202.38" cy="26.778" r="5.4953" fill="#e6e8ec" />
      <path
        d="m324.64 51.199v603.98a27.141 27.141 0 0 1-27.137 27.125h-245.19a27.095 27.095 0 0 1-27.137-27.053v-604.02a27.136 27.136 0 0 1 27.137-27.137h36.698v4.7085a22.345 22.345 0 0 0 22.345 22.345h124.7a22.345 22.345 0 0 0 22.345-22.345v-4.7085h39.106a27.115 27.115 0 0 1 27.137 27.113z"
        fill="#fff"
      />
      <path
        d="m324.64 574.11v81.064a27.141 27.141 0 0 1-27.137 27.125h-245.19a27.095 27.095 0 0 1-27.137-27.053v-81.135z"
        fill="#f2f2f2"
      />
      <g fill="#e6e6e6">
        <path d="m51.506 99.663a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.698a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z" />
        <path d="m142.56 99.663a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.698a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z" />
        <path d="m233.62 99.663a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.698a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z" />
        <path d="m51.656 199.11a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.697a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z" />
        <path d="m142.71 199.11a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.698a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z" />
        <path d="m233.77 199.11a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.698a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z" />
        <path d="m51.806 298.55a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.698a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z" />
        <path d="m142.86 298.55a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.698a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z" />
        <path d="m233.92 298.55a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.698a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z" />
      </g>
      <g fill="#60a5fa">
        <path d="m51.656 592.08a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.697a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z" />
        <path d="m142.71 592.08a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.698a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z" />
        <path d="m233.77 592.08a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.698a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z" />
      </g>
      <path
        d="m51.956 397.99a3.5982 3.5982 0 0 0-3.5943 3.5943v64.698a3.5982 3.5982 0 0 0 3.5943 3.5943h64.698a3.5982 3.5982 0 0 0 3.5943-3.5943v-64.698a3.5982 3.5982 0 0 0-3.5943-3.5943z"
        fill="#e6e6e6"
      />
      <path
        d="m416.32 441.02 3.2778 16.232a13.689 13.689 0 0 0 16.546 10.618 13.689 13.689 0 0 0 8.0277-21.262l-9.4902-13.342-23.477-64.385 4.2859-55.149c-13.474-5.0602-27.191-10.727-34.627-0.73407l9.2383 60.343z"
        fill="#ffb9b9"
      />
      <path
        d="m429.29 452.07a3.1519 3.1519 0 0 0-3.1484 3.1484v56.672a3.1519 3.1519 0 0 0 3.1484 3.1484h56.672a3.1519 3.1519 0 0 0 3.1484-3.1484v-56.672a3.1519 3.1519 0 0 0-3.1484-3.1484z"
        fill="#60a5fa"
      />
      <g fill="#2f2e41">
        <path d="m444.48 231.39c0-0.3344-6e-3 -0.66292-0.0116-1.0088-0.5316-21.061-17.204-37.559-37.957-37.559a37.43 37.43 0 0 0-26.851 11.298 38.741 38.741 0 0 0-11.122 27.27v37.766h75.942z" />
        <path d="m423.57 687.63a7.8683 7.8683 0 0 0-3.5209-7.7167l-22.583-11.083-17.026 2.1494-4.0212 14.416a7.6716 7.6716 0 0 0 6.7112 8.8767l31.771 0.0694a7.8683 7.8683 0 0 0 8.6694-6.7118z" />
        <polygon
          transform="translate(-62.914)"
          points="411.1 475.85 421.17 540.15 424.28 560.02 425.23 566.12 427.88 585.36 440.21 674.94 461.66 670.08 462.68 566.8 462.86 549.08 463.02 534.09 463.17 520.9 463.77 467.88 408.25 457.58 410.87 474.31"
        />
      </g>
      <circle cx="414.47" cy="229.24" r="28.752" fill="#ffb9b9" />
      <path
        d="m382.6 278.36 32.585 16.293a80.126 80.126 0 0 1 10.063-46.482l-28.273-4.3128c-0.38116 11.353-6.1255 22.886-14.376 34.502z"
        fill="#ffb9b9"
      />
      <path
        d="m366.79 390.49 57.503 12.459 4.792-37.377c3.1192-15.986 8.744-31.622-1.1061-44.182l-1.4445-12.919a41.717 41.717 0 0 0-19.349-30.741l-17.395-10.872c-36.056 19.506-34.607 67.687-23.001 123.63z"
        fill="#e6e6e6"
      />
      <polygon
        transform="translate(-62.914)"
        points="427.88 585.36 462.68 566.8 462.86 549.08 463.02 534.09 424.28 560.02 425.23 566.12"
        opacity=".2"
      />
      <path
        d="m345.33 457.58 2.6192 16.731 0.23642 1.5329a62.978 62.978 0 0 0 16.012 17.791l36.054 27.268 0.59559-53.021z"
        opacity=".2"
      />
      <path
        d="m352.89 558.69 6.7087 22.522 86.677-46.217a23.208 23.208 0 0 0 5.8158-35.414l-47.449-53.981 21.085-47.44-60.379-15.813-6.7087 14.376q-21.021 19.622-18.92 45.798a61.64 61.64 0 0 0 24.481 44.113l45.234 34.201z"
        fill="#2f2e41"
      />
      <path
        d="m346.32 601.67a7.8006 7.8006 0 0 0 7.4098-3.9759l12.99-23.274-3.2131-16.708-14.519-3.0584a7.6057 7.6057 0 0 0-8.3539 7.2062l-1.5077 31.661a7.8006 7.8006 0 0 0 7.1935 8.1487z"
        fill="#2f2e41"
      />
      <path
        d="m364.87 447.52-0.51725 16.552a13.689 13.689 0 0 0 13.682 14.117 13.689 13.689 0 0 0 12.673-18.865l-6.1911-15.157-8.1463-68.046 16.772-52.712c-11.962-8.0046-24.021-16.656-33.544-8.6255l-4.792 60.858z"
        fill="#ffb9b9"
      />
      <path
        d="m353.85 322.45c17.655-8.4389 31.188-4.134 41.211 11.022l6.4976-36.25a19.336 19.336 0 0 0-10.742-20.88 15.709 15.709 0 0 0-21.247 8.1777z"
        fill="#e6e6e6"
      />
      <path
        d="m382.96 209.12 7.2166 22.96 62.393-1.1089a38.57 38.57 0 0 0-69.61-21.851z"
        fill="#2f2e41"
      />
    </svg>
  );
}
