import * as React from "react";

export default function UndrawResponsive({ className }) {
  return (
    <svg
      id="ea2d365b-f360-4dd3-a2f3-62c898b94c3b"
      data-name="Layer 1"
      version="1.1"
      viewBox="0 0 558.79 436.38"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden="true"
    >
      <path
        d="m557.79 15h-143.79v9.25a2.0396 2.0396 0 0 0 0.46045 1.3c-0.29 7.23-0.46045 16.46-0.46045 26.49v247.3c0 23.18 0.93018 42.04 2.0703 42.04h139.65c1.1401 0 2.0698-18.86 2.0698-42.04v-247.3c0-10.03-0.16992-19.26-0.46-26.49a2.0392 2.0392 0 0 0 0.46-1.3z"
        fill="#fff"
      />
      <path
        d="m555.72 342.38h-139.65c-1.0884 0-1.8074 0-2.4614-13.278-0.39258-7.9683-0.60889-18.538-0.60889-29.762v-247.3c0-9.6938 0.16382-19.116 0.46119-26.53 0.186-4.4517 0.39917-8.0176 0.6333-10.6 0.45947-5.0557 0.89648-5.9097 1.9758-5.9097h139.65c1.0794 0 1.5161 0.854 1.9758 5.9097 0.23462 2.5923 0.44751 6.1582 0.63306 10.598l2.4e-4 2e-3c0.29712 7.4067 0.46069 16.829 0.46069 26.53v247.3c0 11.224-0.21606 21.794-0.60864 29.762-0.65377 13.278-1.3728 13.278-2.4612 13.278zm-139.08-2h138.51c0.72364-2.7329 1.6379-17.57 1.6379-41.04v-247.3c0-9.6753-0.16308-19.068-0.459-26.448h-2.4e-4c-0.18409-4.4082-0.395-7.9414-0.62647-10.501a28.884 28.884 0 0 0-0.56177-4.0903h-138.49a28.896 28.896 0 0 0-0.56176 4.0903c-0.2312 2.5503-0.4419 6.0835-0.62671 10.501-0.29615 7.3867-0.45948 16.78-0.45948 26.448v247.3c0 23.469 0.91456 38.307 1.6384 41.04z"
        fill="#e4e4e4"
      />
      <path
        d="m555.72 9.9999h-139.65a2.0743 2.0743 0 0 0-2.0703 2.07v12.18a2.0396 2.0396 0 0 0 0.46045 1.3 2.0795 2.0795 0 0 0 1.6099 0.77h139.65a2.0802 2.0802 0 0 0 1.6099-0.77 2.0392 2.0392 0 0 0 0.46-1.3v-12.18a2.0739 2.0739 0 0 0-2.0699-2.07z"
        fill="#cacaca"
      />
      <g fill="#fff">
        <circle cx="426.02" cy="17.565" r="2.9396" data-name="Ellipse 90" />
        <circle cx="437.18" cy="17.565" r="2.9396" data-name="Ellipse 91" />
        <circle cx="448.34" cy="17.565" r="2.9396" data-name="Ellipse 92" />
        <path d="m544.79 13.64h-12.85a0.95609 0.95609 0 0 0-0.94971 0.95 0.80091 0.80091 0 0 0 0.1001 0.41 0.94344 0.94344 0 0 0 0.84961 0.54h12.85a0.948 0.948 0 0 0 0.86036-0.54 0.91378 0.91378 0 0 0 0.0898-0.41 0.94956 0.94956 0 0 0-0.9502-0.95z" />
        <path d="m544.79 17.21h-12.85a0.95 0.95 0 0 0 0 1.9h12.85a0.95 0.95 0 1 0 0-1.9z" />
        <path d="m544.79 20.78h-12.85a0.95 0.95 0 0 0 0 1.9h12.85a0.95 0.95 0 1 0 0-1.9z" />
      </g>
      <path
        d="m499.79 53.02h-26.44a5.002 5.002 0 0 0-5 5v22.9a5.0084 5.0084 0 0 0 5 5h26.44a5.0147 5.0147 0 0 0 5-5v-22.9a5.0082 5.0082 0 0 0-5-5z"
        fill="#60a5fa"
      />
      <path
        d="m532.64 100.88h-92.15a2.27 2.27 0 1 0 0 4.54h92.15a2.27 2.27 0 1 0 0-4.54z"
        fill="#e4e4e4"
      />
      <path
        d="m475.49 111.52h-35a2.27 2.27 0 1 0 0 4.54h35a2.27 2.27 0 1 0 0-4.54z"
        fill="#e4e4e4"
      />
      <path
        d="m499.79 152.02h-26.44a5.002 5.002 0 0 0-5 5v22.9a5.0084 5.0084 0 0 0 5 5h26.44a5.0147 5.0147 0 0 0 5-5v-22.9a5.0082 5.0082 0 0 0-5-5z"
        fill="#60a5fa"
      />
      <path
        d="m532.64 199.88h-92.15a2.27 2.27 0 1 0 0 4.54h92.15a2.27 2.27 0 1 0 0-4.54z"
        fill="#e4e4e4"
      />
      <path
        d="m475.49 210.52h-35a2.27 2.27 0 1 0 0 4.54h35a2.27 2.27 0 1 0 0-4.54z"
        fill="#e4e4e4"
      />
      <path
        d="m499.79 251.02h-26.44a5.002 5.002 0 0 0-5 5v22.9a5.0084 5.0084 0 0 0 5 5h26.44a5.0147 5.0147 0 0 0 5-5v-22.9a5.0082 5.0082 0 0 0-5-5z"
        fill="#60a5fa"
      />
      <path
        d="m532.64 298.88h-92.15a2.27 2.27 0 1 0 0 4.54h92.15a2.27 2.27 0 1 0 0-4.54z"
        fill="#e4e4e4"
      />
      <path
        d="m475.49 309.52h-35a2.27 2.27 0 1 0 0 4.54h35a2.27 2.27 0 1 0 0-4.54z"
        fill="#e4e4e4"
      />
      <path
        d="m374.79 3.0699a2.0739 2.0739 0 0 0-2.0698-2.07h-369.65a2.0743 2.0743 0 0 0-2.0703 2.07v12.18a2.0169 2.0169 0 0 0 0.53028 1.37c-0.33008 8.27-0.53028 19.17-0.53028 31.11v274.91c0 25.78 0.93018 46.74 2.0703 46.74h369.65c1.1401 0 2.0698-20.96 2.0698-46.74v-274.91c0-11.94-0.1997-22.84-0.52978-31.11a2.0164 2.0164 0 0 0 0.52978-1.37z"
        fill="#fff"
      />
      <path
        d="m372.72 370.38h-369.65c-1.13 0-1.8121 0-2.4618-14.658-0.39246-8.855-0.60853-20.604-0.60853-33.082v-274.91c0-11.507 0.1886-22.57 0.53113-31.15 0.65735-16.58 1.406-16.58 2.5392-16.58h369.65c1.1331 0 1.8818 3e-3 2.5393 16.58 0.342 8.5723 0.53051 19.635 0.53051 31.15v274.91c0 12.479-0.21606 24.228-0.60839 33.082-0.64942 14.658-1.3316 14.658-2.4614 14.658zm-369.07-2h368.48c0.81811-3.1352 1.6536-20.74 1.6536-45.74v-274.91c0-11.489-0.188-22.524-0.529-31.07v-4.8e-4c-0.4165-10.503-0.90283-13.7-1.1352-14.66h-368.46c-0.23254 0.96-0.71887 4.1562-1.1355 14.66-0.34143 8.5547-0.52942 19.589-0.52942 31.07v274.91c0 25 0.83563 42.604 1.6541 45.74z"
        fill="#e4e4e4"
      />
      <path
        d="m372.72 0.99992h-369.65a2.0743 2.0743 0 0 0-2.0703 2.07v12.18a2.0169 2.0169 0 0 0 0.53028 1.37 2.0512 2.0512 0 0 0 1.54 0.7h369.65a2.0512 2.0512 0 0 0 1.54-0.7 2.0164 2.0164 0 0 0 0.52978-1.37v-12.18a2.0739 2.0739 0 0 0-2.0697-2.07z"
        fill="#cacaca"
      />
      <g fill="#fff">
        <circle
          id="b7005a6f-2f7f-4001-b097-d8b6c117468f"
          cx="13.02"
          cy="8.5654"
          r="2.9396"
          data-name="Ellipse 90"
        />
        <circle
          id="a0adf4f5-8105-4330-a35a-c3adb8f4e7bc"
          cx="24.178"
          cy="8.5654"
          r="2.9396"
          data-name="Ellipse 91"
        />
        <circle
          id="a64b73a5-7207-44c1-bcaf-f99ab7b9c6dc"
          cx="35.336"
          cy="8.5654"
          r="2.9396"
          data-name="Ellipse 92"
        />
        <path d="m361.79 4.64h-12.85a0.95 0.95 0 0 0 0 1.9h12.85a0.95 0.95 0 1 0 0-1.9z" />
        <path d="m361.79 8.21h-12.85a0.95 0.95 0 0 0 0 1.9h12.85a0.95 0.95 0 1 0 0-1.9z" />
        <path d="m361.79 11.78h-12.85a0.95 0.95 0 0 0 0 1.9h12.85a0.95 0.95 0 1 0 0-1.9z" />
      </g>
      <path
        d="m122.55 62.02h-43.26a8.1897 8.1897 0 0 0-8.1797 8.18v37.47a8.1897 8.1897 0 0 0 8.1797 8.18h43.26a8.1897 8.1897 0 0 0 8.1797-8.18v-37.47a8.1897 8.1897 0 0 0-8.1797-8.18z"
        fill="#60a5fa"
      />
      <path
        d="m300.98 76.51h-150.78a3.715 3.715 0 0 0 0 7.43h150.78a3.715 3.715 0 0 0 0-7.43z"
        fill="#e4e4e4"
      />
      <path
        d="m207.47 93.92h-57.27a3.715 3.715 0 0 0 0 7.43h57.27a3.715 3.715 0 0 0 0-7.43z"
        fill="#e4e4e4"
      />
      <path
        d="m122.55 162.02h-43.26a8.1897 8.1897 0 0 0-8.1797 8.18v37.47a8.1897 8.1897 0 0 0 8.1797 8.18h43.26a8.1897 8.1897 0 0 0 8.1797-8.18v-37.47a8.1897 8.1897 0 0 0-8.1797-8.18z"
        fill="#60a5fa"
      />
      <path
        d="m300.98 176.51h-150.78a3.715 3.715 0 0 0 0 7.43h150.78a3.715 3.715 0 0 0 0-7.43z"
        fill="#e4e4e4"
      />
      <path
        d="m207.47 193.92h-57.27a3.715 3.715 0 0 0 0 7.43h57.27a3.715 3.715 0 0 0 0-7.43z"
        fill="#e4e4e4"
      />
      <path
        d="m122.55 262.02h-43.26a8.1897 8.1897 0 0 0-8.1797 8.18v37.47a8.1897 8.1897 0 0 0 8.1797 8.18h43.26a8.1897 8.1897 0 0 0 8.1797-8.18v-37.47a8.1897 8.1897 0 0 0-8.1797-8.18z"
        fill="#60a5fa"
      />
      <path
        d="m300.98 276.51h-150.78a3.715 3.715 0 0 0 0 7.43h150.78a3.715 3.715 0 0 0 0-7.43z"
        fill="#e4e4e4"
      />
      <path
        d="m207.47 293.92h-57.27a3.715 3.715 0 0 0 0 7.43h57.27a3.715 3.715 0 0 0 0-7.43z"
        fill="#e4e4e4"
      />
      <path
        d="m438.72 435.38h-247.65c-1.1418 0-2.0705-17.549-2.0705-39.123v-230.13c0-21.574 0.92877-39.123 2.0705-39.123h247.65c1.1418 0 2.0705 17.549 2.0705 39.123v230.13c3e-5 21.574-0.92877 39.123-2.0705 39.123z"
        fill="#fff"
      />
      <path
        d="m438.72 436.38h-247.65c-1.0476 0-1.8047 0-2.4619-12.418-0.39258-7.415-0.60864-17.254-0.60864-27.705v-230.13c0-10.451 0.21606-20.29 0.60864-27.705 0.65723-12.419 1.4143-12.419 2.4619-12.419h247.65c1.0476 0 1.8047 0 2.4619 12.419 0.39258 7.4146 0.60865 17.254 0.60865 27.705v230.13c0 10.451-0.21607 20.29-0.60865 27.705-0.65722 12.418-1.4143 12.418-2.4619 12.418zm-247.1-2h246.54c0.71778-2.5908 1.624-16.394 1.624-38.124v-230.13c0-21.73-0.90625-35.533-1.624-38.124h-246.54c-0.71777 2.5908-1.624 16.394-1.624 38.124v230.13c-6e-5 21.729 0.90623 35.533 1.624 38.124z"
        fill="#e4e4e4"
      />
      <path
        d="m438.72 143.32h-247.65a2.0728 2.0728 0 0 1-2.0705-2.0705v-12.179a2.0728 2.0728 0 0 1 2.0705-2.0705h247.65a2.0728 2.0728 0 0 1 2.0705 2.0705v12.179a2.0728 2.0728 0 0 1-2.0705 2.0705z"
        fill="#cacaca"
      />
      <g fill="#fff">
        <circle
          id="a9432236-99f6-4733-9920-e312ad95354d"
          cx="201.02"
          cy="134.57"
          r="2.9396"
          data-name="Ellipse 90"
        />
        <circle cx="212.18" cy="134.57" r="2.9396" data-name="Ellipse 91" />
        <circle
          id="bc1189a4-69b8-41a6-80e2-1e52c3b8abfb"
          cx="223.34"
          cy="134.57"
          r="2.9396"
          data-name="Ellipse 92"
        />
        <path d="m427.79 132.54h-12.849a0.95176 0.95176 0 0 1 0-1.9035h12.849a0.95176 0.95176 0 0 1 0 1.9035z" />
        <path d="m427.79 136.11h-12.849a0.95175 0.95175 0 0 1 0-1.9035h12.849a0.95175 0.95175 0 1 1 0 1.9035z" />
        <path d="m427.79 139.68h-12.849a0.95176 0.95176 0 0 1 0-1.9035h12.849a0.95176 0.95176 0 0 1 0 1.9035z" />
      </g>
      <path
        d="m267.17 218.92h-31.593a5.9814 5.9814 0 0 1-5.9745-5.9745v-27.363a5.9814 5.9814 0 0 1 5.9745-5.9745h31.593a5.9814 5.9814 0 0 1 5.9745 5.9745v27.363a5.9814 5.9814 0 0 1-5.9745 5.9746z"
        fill="#60a5fa"
      />
      <path
        d="m397.48 195.62h-110.11a2.7125 2.7125 0 0 1 0-5.4249h110.11a2.7125 2.7125 0 0 1 0 5.4249z"
        fill="#e4e4e4"
      />
      <path
        d="m329.19 208.33h-41.822a2.7125 2.7125 0 1 1 0-5.4249h41.822a2.7125 2.7125 0 1 1 0 5.4249z"
        fill="#e4e4e4"
      />
      <path
        d="m267.17 299.92h-31.593a5.9814 5.9814 0 0 1-5.9745-5.9745v-27.363a5.9814 5.9814 0 0 1 5.9745-5.9745h31.593a5.9814 5.9814 0 0 1 5.9745 5.9745v27.363a5.9814 5.9814 0 0 1-5.9745 5.9746z"
        fill="#60a5fa"
      />
      <path
        d="m397.48 276.62h-110.11a2.7125 2.7125 0 0 1 0-5.4249h110.11a2.7125 2.7125 0 0 1 0 5.4249z"
        fill="#e4e4e4"
      />
      <path
        d="m329.19 289.33h-41.822a2.7125 2.7125 0 1 1 0-5.4249h41.822a2.7125 2.7125 0 1 1 0 5.4249z"
        fill="#e4e4e4"
      />
      <path
        d="m267.17 380.92h-31.593a5.9814 5.9814 0 0 1-5.9745-5.9745v-27.363a5.9814 5.9814 0 0 1 5.9745-5.9745h31.593a5.9814 5.9814 0 0 1 5.9745 5.9745v27.363a5.9814 5.9814 0 0 1-5.9745 5.9746z"
        fill="#60a5fa"
      />
      <path
        d="m397.48 357.62h-110.11a2.7125 2.7125 0 1 1 0-5.4249h110.11a2.7125 2.7125 0 1 1 0 5.4249z"
        fill="#e4e4e4"
      />
      <path
        d="m329.19 370.33h-41.822a2.7125 2.7125 0 1 1 0-5.4249h41.822a2.7125 2.7125 0 1 1 0 5.4249z"
        fill="#e4e4e4"
      />
    </svg>
  );
}
