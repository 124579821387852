import * as React from "react";

export default function UndrawFastLoading({ className }) {
  return (
    <svg
      id="bf250802-154c-4340-a718-5462dd95d90e"
      data-name="Layer 1"
      version="1.1"
      viewBox="0 0 540.24 711.53"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden="true"
    >
      <path
        d="m354.38 168.89h-3.9v-106.98a61.915 61.915 0 0 0-61.915-61.915h-226.65a61.915 61.915 0 0 0-61.916 61.914v586.88a61.915 61.915 0 0 0 61.915 61.915h226.65a61.915 61.915 0 0 0 61.915-61.915v-403.76h3.9z"
        fill="#3f3d56"
        data-name="Path 968"
      />
      <path
        d="m337.78 57.768v595.18a46.959 46.959 0 0 1-46.942 46.952h-231.3a46.966 46.966 0 0 1-46.973-46.952v-595.18a46.965 46.965 0 0 1 46.971-46.951h28.058a22.329 22.329 0 0 0 20.656 30.74h131.87a22.329 22.329 0 0 0 20.656-30.74h30.055a46.959 46.959 0 0 1 46.951 46.942z"
        fill="#fff"
        data-name="Path 969"
      />
      <g fill="#e6e6e6">
        <path
          d="m280.45 400.6h-205.54a3.81 3.81 0 0 1-3.806-3.806v-50.984a3.811 3.811 0 0 1 3.806-3.806h205.54a3.811 3.811 0 0 1 3.806 3.806v50.985a3.811 3.811 0 0 1-3.806 3.806zm-205.54-57.074a2.286 2.286 0 0 0-2.284 2.284v50.985a2.286 2.286 0 0 0 2.284 2.284h205.54a2.286 2.286 0 0 0 2.284-2.284v-50.985a2.286 2.286 0 0 0-2.284-2.284z"
          data-name="Path 39"
        />
        <path
          id="a3f57aef-fb20-4553-8f82-b751eb5ec42c"
          d="m138.48 360.36a2.6645 2.6645 0 0 0 0 5.329h125.6a2.665 2.665 0 0 0 0.2041-5.3261q-0.0585-2e-3 -0.11712-2e-3h-125.69z"
          data-name="Path 40"
        />
        <path
          d="m138.48 376.34a2.6645 2.6645 0 0 0 0 5.329h125.6a2.665 2.665 0 0 0 0.2041-5.3261q-0.0585-2e-3 -0.11712-2e-3h-125.69z"
          data-name="Path 41"
        />
        <path
          id="f83fafa3-5270-49ac-8952-eedc3d6c92ed"
          d="m280.45 485.58h-205.54a3.81 3.81 0 0 1-3.806-3.806v-50.984a3.811 3.811 0 0 1 3.806-3.806h205.54a3.811 3.811 0 0 1 3.806 3.806v50.985a3.811 3.811 0 0 1-3.806 3.805zm-205.54-57.074a2.286 2.286 0 0 0-2.284 2.284v50.985a2.286 2.286 0 0 0 2.284 2.284h205.54a2.286 2.286 0 0 0 2.284-2.284v-50.985a2.286 2.286 0 0 0-2.284-2.284z"
          data-name="Path 42"
        />
        <path
          id="b1e095e5-1d51-47f4-b1e9-85a21efab4ec"
          d="m138.48 445.62a2.6645 2.6645 0 0 0 0 5.329h125.6a2.665 2.665 0 0 0 0.087-5.328h-125.69z"
          data-name="Path 43"
        />
        <path
          id="e7ccabbb-2f45-40c9-adc9-a92013dc0f4d"
          d="m138.48 461.61a2.6645 2.6645 0 0 0 0 5.329h125.6a2.665 2.665 0 0 0 0.087-5.328h-125.69z"
          data-name="Path 44"
        />
        <path
          d="m280.45 570.83h-205.54a3.81 3.81 0 0 1-3.806-3.806v-50.984a3.811 3.811 0 0 1 3.806-3.806h205.54a3.811 3.811 0 0 1 3.806 3.806v50.985a3.811 3.811 0 0 1-3.806 3.806zm-205.54-57.074a2.286 2.286 0 0 0-2.284 2.284v50.985a2.286 2.286 0 0 0 2.284 2.284h205.54a2.286 2.286 0 0 0 2.284-2.284v-50.985a2.286 2.286 0 0 0-2.284-2.284z"
          data-name="Path 39-2"
        />
        <path
          d="m138.48 530.88a2.6645 2.6645 0 1 0 0 5.329h125.6a2.665 2.665 0 0 0 0.2041-5.3261q-0.0585-2e-3 -0.11712-2e-3h-125.69z"
          data-name="Path 40-2"
        />
        <path
          d="m138.48 546.86a2.6645 2.6645 0 0 0 0 5.329h125.6a2.665 2.665 0 0 0 0.2041-5.3261q-0.0585-2e-3 -0.11712-2e-3h-125.69z"
          data-name="Path 41-2"
        />
      </g>
      <g
        id="bf60e786-3805-43a5-8cdd-bbea060079bf"
        transform="translate(-145.36 2.0016e-5)"
        data-name="Group 59"
      >
        <circle
          cx="245.92"
          cy="370.98"
          r="15.986"
          fill="#60a5fa"
          data-name="Ellipse 5"
        />
        <path
          transform="translate(-253.01 -93.713)"
          d="m491.28 461.71c-0.184 0-0.333 1.193-0.333 2.664s0.149 2.665 0.333 2.665h15.719c0.184 0.024 0.336-1.149 0.339-2.62a5.9431 5.9431 0 0 0-0.328-2.708h-15.73z"
          fill="#e6e6e6"
          data-name="Path 40-3"
        />
      </g>
      <g
        id="b51141dd-8175-4f5a-b697-41b89601b37f"
        transform="translate(-145.36 2.0016e-5)"
        data-name="Group 60"
      >
        <circle
          cx="245.92"
          cy="456.28"
          r="15.986"
          fill="#60a5fa"
          data-name="Ellipse 5-2"
        />
        <path
          id="bf4b9d9b-b2cd-48cd-bc0b-2e6b007ef33d"
          transform="translate(-253.01 -93.713)"
          d="m491.28 547.01c-0.184 0-0.333 1.193-0.333 2.664s0.149 2.665 0.333 2.665h15.719c0.184 0.024 0.336-1.149 0.339-2.62a5.9431 5.9431 0 0 0-0.328-2.708h-15.73z"
          fill="#e6e6e6"
          data-name="Path 40-4"
        />
      </g>
      <g
        id="b35aa588-fd9e-4429-8570-fee6b5da93cc"
        transform="translate(-145.36 2.0016e-5)"
        data-name="Group 61"
      >
        <circle
          id="b896a996-d737-41ee-9642-d5eaf8a9fd6b"
          cx="245.92"
          cy="541.54"
          r="15.986"
          fill="#60a5fa"
          data-name="Ellipse 5-3"
        />
        <path
          transform="translate(-253.01 -93.713)"
          d="m491.28 632.26c-0.184 0-0.333 1.193-0.333 2.664s0.149 2.665 0.333 2.665h15.719c0.184 0.024 0.336-1.149 0.339-2.62a5.9431 5.9431 0 0 0-0.328-2.708h-15.73z"
          fill="#e6e6e6"
          data-name="Path 40-5"
        />
      </g>
      <path
        d="m226.82 256.67a4.4866 4.4866 0 0 1-4.0124-2.4497 52.874 52.874 0 0 0-31.372-26.411 59.999 59.999 0 0 0-8.408-1.9038l-0.57959-0.0879 29.92-93.546 0.50586 0.0176c58.128 15.795 91.385 62.381 100.19 76.283a4.4938 4.4938 0 0 1-1.7966 6.4233l-82.431 41.197a4.5068 4.5068 0 0 1-2.0193 0.47753z"
        fill="#e6e6e6"
      />
      <path
        d="m406.26 455.99a9.2759 9.2759 0 0 1 12.711-6.383l22.283-24.293 4.164 16.616-21.8 20.521a9.326 9.326 0 0 1-17.359-6.462z"
        fill="#feb8b8"
        data-name="Path 2881"
      />
      <path
        d="m454.12 698.33h-13.613l-6.478-52.517h20.1z"
        fill="#feb8b8"
        data-name="Path 2882"
      />
      <path
        d="m457.59 693.89h-26.815a17.089 17.089 0 0 0-17.088 17.087v0.556h43.9z"
        fill="#2f2e41"
        data-name="Path 2883"
      />
      <path
        d="m532.31 684.84-12.729 4.832-24.693-46.8 18.787-7.13z"
        fill="#feb8b8"
        data-name="Path 2884"
      />
      <path
        d="m533.98 679.45-25.069 9.515a17.089 17.089 0 0 0-9.911 22.039l0.2 0.519 41.045-15.578z"
        fill="#2f2e41"
        data-name="Path 2885"
      />
      <path
        id="aea1be6b-88fd-4404-a4b2-fdb70dea932e"
        d="m487.49 523.65 11.283 60.714s30.625 85.428 28.476 87.577-31.7 9.671-30.088 4.836-35.461-77.369-35.461-77.369z"
        fill="#2f2e41"
        data-name="Path 2886"
      />
      <path
        id="a45d4c0e-5c31-49a4-b00c-14ea50dd8cbe"
        d="m441.73 413.32s-22.777 29.774-19.792 31.4 14.547 10.236 14.547 10.236l11.764-32.284z"
        fill="#e4e4e4"
        data-name="Path 2887"
      />
      <path
        d="m435.37 443.37s-3.761 27.939-2.149 33.849 0.537 173.54-0.537 176.23-4.3 11.82-2.686 16.118 23.64 15.044 26.864 11.283 26.866-140.77 26.866-140.77 33.312-82.742 25.79-90.8-74.148-5.911-74.148-5.911z"
        fill="#2f2e41"
        data-name="Path 2888"
      />
      <circle
        cx="483.71"
        cy="237.48"
        r="26.327"
        fill="#feb8b8"
        data-name="Ellipse 542"
      />
      <path
        d="m456.2 296.98a18.664 18.664 0 0 1 6-11.04l4.8-14.675h28.209l7.861 12.335c8.5 3.661 14.8 9.467 15.039 16.311 1.3 4.545-4.3 148.83-5.91 154.2-0.473 1.564-6.9 2.4-15.936 2.772-8.285 0.338-18.756 0.29-28.809 0.059-16.645-0.387-32.162-1.279-34.773-1.757-5.905-1.073 22.934-154.82 23.519-158.2z"
        fill="#e4e4e4"
        data-name="Path 2890"
      />
      <path
        d="m481.14 488.31a9.276 9.276 0 0 0 1.826-14.106l15.569-29.056-17.059 1.558-12.168 27.352a9.326 9.326 0 0 0 11.833 14.251z"
        fill="#feb8b8"
        data-name="Path 2891"
      />
      <path
        d="m483.73 281.11s-24.715 10.208-15.581 36 19.342 56.415 19.342 56.415-12.895 69.31-12.895 72-8.059 17.193-4.836 19.88 24.715 8.059 25.252 5.373a181.72 181.72 0 0 1 5.91-18.268c1.075-2.149 26.327-69.31 23.1-75.22-2.372-4.349-4.454-44.2-5.388-64.939a34.107 34.107 0 0 0-17.781-28.715c-5.004-2.662-10.836-4.098-17.123-2.526z"
        fill="#e4e4e4"
        data-name="Path 2893"
      />
      <path
        id="a3aad1c3-1778-4515-92de-8de9e018032c"
        d="m493.84 249.25c-2-0.922-4.317-1.113-6.479-1.686-7.734-2.052-12.916-9.689-11.261-16.6a7.479 7.479 0 0 0 0.406-2.736c-0.289-2.047-2.687-3.368-4.986-3.837s-4.746-0.4-6.943-1.155a9.39 9.39 0 0 1-6.136-7.366 13.674 13.674 0 0 1 2.327-9.171l0.831 2.088a7.7712 7.7712 0 0 0 2.714-3.545 5.5 5.5 0 0 1 4.26 2.992c1.333 0.687 1.525-2.133 3-2.549a2.945 2.945 0 0 1 1.838 0.4c2.967 1.209 6.414 0.175 9.6-0.495a41.222 41.222 0 0 1 16.771-0.017c3.663 0.763 7.29 2.093 9.912 4.455a20.35 20.35 0 0 1 4.636 6.96c2.812 6.182 4.669 12.871 3.741 19.473a26.051 26.051 0 0 1-7.436 14.6c-2.123 2.137-9.05 9.591-12.848 8.321-4.778-1.6 1.676-7.535-3.947-10.132z"
        fill="#2f2e41"
        data-name="Path 2800"
      />
      <circle cx="176.62" cy="266.46" r="8" fill="#3f3d56" />
      <path
        d="m122.93 256.55a4.492 4.492 0 0 1-2.1001-0.52442l-81.953-43.234a4.5 4.5 0 0 1-1.7412-6.3247 181.67 181.67 0 0 1 40.991-45.975c28.302-22.431 60.494-33.684 95.678-33.495a151.88 151.88 0 0 1 39.064 5.2886l0.50464 0.13672-29.927 93.541-0.42114-0.0635a66.682 66.682 0 0 0-8.1387-0.72168c-30.451-0.85938-43.646 19.876-47.908 28.83a4.4145 4.4145 0 0 1-2.6201 2.31 4.5273 4.5273 0 0 1-1.4288 0.23293z"
        fill="#60a5fa"
      />
      <path
        d="m212.75 132.77-21.16 94.56a60.384 60.384 0 0 0-8.48-1.92l29.63-92.64z"
        fill="#3f3d56"
      />
    </svg>
  );
}
