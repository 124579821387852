import * as React from "react";

import Swoop from "./Swoop";
import WordMark from "./SVG/WordMark";

export default function Footer() {
  return (
    <footer className="text-white bg-gradient-to-br from-primary-600 to-primary-400 pb-safe">
      <div className="flex justify-center relative">
        <Swoop type="top" colorHex="#ffffff" />
      </div>
      <div className="px-4 py-10 sm:py-10 lg:px-28">
        <WordMark labelId="wordMark2" className="w-56 sm:w-80" />
        <div className="mt-10 text-sm sm:text-lg flex flex-col space-y-2">
          <a href="mailto:cary@dottewebdesign.com">cary@dottewebdesign.com</a>
        </div>
      </div>
    </footer>
  );
}