import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { HiCheckCircle } from "@react-icons/all-files/hi/HiCheckCircle";

import SEO from "../components/SEO";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Swoop from "../components/Swoop";
import Button from "../components/Button";
import ContactForm from "../components/ContactForm";
import UndrawFastLoading from "../components/SVG/UndrawFastLoading";
import UndrawResponsive from "../components/SVG/UndrawResponsive";
import UndrawSearchEngines from "../components/SVG/UndrawSearchEngines";
import UndrawWorkingFromAnywhere from "../components/SVG/UndrawWorkingFromAnywhere";
import UndrawHomeScreen from "../components/SVG/UndrawHomeScreen";
import UndrawSecurityOn from "../components/SVG/UndrawSecurityOn";
import UndrawConnectedWorld from "../components/SVG/UndrawConnectedWorld";
import Circle from "../components/SVG/Circle";

function Feature({ heading, svg, children }) {
  return (
    <div className="space-y-3">
      <div className="flex justify-center sm:justify-start">{svg}</div>
      <h3 className="text-center sm:text-left text-xl text-gray-800 font-bold">
        {heading}
      </h3>
      <p className="text-center sm:text-left text-gray-600">
        {children}
      </p>
    </div>
  );
}

function CheckMarkListItem({ children }) {
  return (
    <li className="flex items-start space-x-2">
      <div>
        <HiCheckCircle className="w-5 h-5 text-primary-400" />
      </div>
      <div>{children}</div>
    </li>
  );
}

export default function IndexPage() {
  return (
    <div className="max-w-screen-xl mx-auto">
      <SEO landingPage={true} />

      <header className="text-white bg-gradient-to-br from-primary-600 to-primary-400">
        <div className="relative">
          <div className="absolute top-4 lg:top-10 right-4 lg:right-28">
            <div className="grid grid-cols-3 gap-10">
              <Circle className="w-20" />
              <Circle className="w-20" />
              <Circle className="w-20" />
              <div></div>
              <Circle className="w-20" />
              <Circle className="w-20" />
              <div></div>
              <div></div>
              <Circle className="w-20" />
            </div>
          </div>
        </div>

        <Nav />

        <div className="relative pt-14 pb-24 mx-4 lg:mx-28 sm:w-1/2 lg:w-2/5 lg:pb-0">
          <h1 className="text-5xl lg:text-6xl font-bold capitalize mb-8">
            Modern Websites for Kansas City
          </h1>
          <p className="mb-10 text-sm lg:text-base text-white">
            We develop performant, mobile-friendly websites and set up
            hosting on a fast, secure, and reliable global platform. We will
            also set up your domain, email, analytics, and online business
            listings.
          </p>
          <Link to="/#contact">
            <Button dark={true}>Get in Touch</Button>
          </Link>
        </div>
        <div className="flex justify-end relative">
          <div className="absolute right-4 lg:right-28 -bottom-6 sm:bottom-36 lg:bottom-20 w-3/4 sm:w-7/12 lg:w-1/2">
            <StaticImage
              src="../images/responsive-design.png"
              placeholder="blurred"
              alt="A website shown on various screen sizes."
            />
          </div>
          <Swoop type="bottom" colorHex="#ffffff" />
        </div>
      </header>

      <section id="features" className="text-gray-800 mt-20 sm:mt-0">
        <div className="relative" style={{ zIndex: -1 }}>
          <div className="absolute -top-12 sm:top-0 left-4 lg:left-28">
            <div className="grid grid-cols-3 gap-10">
              <Circle className="w-20" />
              <Circle className="w-20" />
              <Circle className="w-20" />

              <Circle className="w-20" />
              <Circle className="w-20" />
              <div></div>

              <Circle className="w-20" />
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="flex justify-center lg:mx-28">
          <h2 className="text-center capitalize font-bold text-3xl lg:text-4xl text-gray-800 mb-20 w-72 lg:w-96">
            Websites packed with features
          </h2>
        </div>
        <div className="space-y-20 sm:space-y-0 mx-4 lg:mx-28 sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:content-start sm:gap-20">
          <Feature
            heading="High Performance"
            svg={<UndrawFastLoading className="w-20 lg:w-auto sm:h-20" />}
          >
            Resource optimization, lazy-loading, and prefetching make our
            websites fast, and fast websites enjoy better SEO.
          </Feature>
          <Feature
            heading="Offline Support"
            svg={
              <UndrawWorkingFromAnywhere className="w-28 lg:w-auto sm:h-20" />
            }
          >
            Service workers make our websites resistant to poor network
            connections. Some of our websites work entirely offline.
          </Feature>
          <Feature
            heading="Mobile-Friendly"
            svg={<UndrawResponsive className="w-32 lg:w-auto sm:h-20" />}
          >
            Our websites properly fit all screen sizes, making them perfect for
            the growing population of mobile users.
          </Feature>
          <Feature
            heading="Search Engine Optimization"
            svg={<UndrawSearchEngines className="w-32 lg:w-auto sm:h-20" />}
          >
            We use metadata, structured data, and keyword optimization to make
            our websites rank well on search engines.
          </Feature>
          <Feature
            heading="Installable"
            svg={<UndrawHomeScreen className="w-20 lg:w-auto sm:h-20" />}
          >
            All of our websites act like mobile applications, meaning that users
            can add our websites to their home screen.
          </Feature>
          <Feature
            heading="Security"
            svg={<UndrawSecurityOn className="w-32 lg:w-auto sm:h-20" />}
          >
            All of our websites consist only of static files, making them
            invulnerable to DDoS attacks and malicious requests.
          </Feature>
        </div>
      </section>

      <div className="relative" style={{ zIndex: -1 }}>
        <div className="absolute -top-48 left-4 lg:left-28">
          <div className="grid grid-cols-3 gap-10">
            <Circle className="w-20" />
            <div></div>
            <div></div>
            <Circle className="w-20" />
            <Circle className="w-20" />
            <div></div>
            <Circle className="w-20" />
            <Circle className="w-20" />
            <Circle className="w-20" />
          </div>
        </div>
      </div>

      <section id="hosting" className="text-gray-800 mx-4 lg:mx-28 mt-40">
        <div className="space-y-10 sm:space-y-0 sm:gap-20 sm:grid sm:grid-cols-2">
          <UndrawConnectedWorld className="w-full" />
          <div>
            <div className="flex justify-center sm:justify-start">
              <h2 className="text-center sm:text-left capitalize font-bold text-3xl lg:text-4xl text-gray-800 mb-20 w-72 lg:w-96">
                Fast, reliable, and secure hosting
              </h2>
            </div>
            <p className="text-lg text-gray-600">
              We'll set up your domain and hosting on a secure, reliable, and
              fast global platform that includes a content delivery network,
              load balancing, HTTPS, and DDoS mitigation. We will also set up
              other services, such as email, analytics, and online business
              listings, including Google Maps.
            </p>
          </div>
        </div>
      </section>

      <div className="relative" style={{ zIndex: -1 }}>
        <div className="absolute -bottom-64 sm:-bottom-64 right-4 lg:right-28">
          <div className="grid grid-cols-3 gap-10">
            <div></div>
            <div></div>
            <Circle className="w-20" />
            <div></div>
            <Circle className="w-20" />
            <Circle className="w-20" />
            <Circle className="w-20" />
            <Circle className="w-20" />
            <Circle className="w-20" />
          </div>
        </div>
      </div>

      <section id="contact" className="mt-40">
        <div className="flex justify-center lg:mx-28">
          <h2 className="z-50 text-center capitalize font-bold text-3xl lg:text-4xl text-gray-800 mb-20 w-72 lg:w-96">
            Get in touch
          </h2>
        </div>
        <div className="space-y-10 sm:space-y-0 mx-4 mb-20 lg:mx-28 sm:gap-20 sm:grid sm:grid-cols-2 sm:-mb-52 lg:-mb-60">
          <div className="text-lg flex flex-col space-y-3 text-gray-600">
            <div className="py-3 px-2 border-l-4 border-primary-400">
              <a href="mailto:cary@dottewebdesign.com">
                cary@dottewebdesign.com
              </a>
            </div>
          </div>
          <div className="bg-white sm:p-4 sm:shadow-lg sm:rounded z-50 sm:border-t-4 sm:border-primary-400">
            <ContactForm />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
