import * as React from "react";

type Props = {
  type: "bottom" | "top";
  colorHex: string;
  className?: string;
};

export default function Swoop({ type, colorHex, className }: Props) {
  return (
    <svg
      width="100%"
      height="100%"
      id="svg"
      viewBox="0 0 1440 275"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className={`${type === "bottom" ? "-mb-px" : "-mt-px"} ${className}`}
    >
      {type === "bottom" ? (
        <path
          d="m1070.1 7.8125c40.853-0.10669 80.488 2.7648 116.55 9.6875 104.93 20.139 179.64 74.569 254.35 129v128.5h-1442v-128.5c84.414 64.115 168.82 128.23 264.37 123 95.543-5.23 202.2-79.804 295.41-132 93.206-52.196 172.95-82.014 280.39-104 70.505-14.428 152.93-25.484 230.93-25.687z"
          stroke="none"
          strokeWidth="0"
          fill={colorHex}
        ></path>
      ) : (
        <path
          d="m369.9 267.19c-40.853 0.10669-80.488-2.7648-116.55-9.6875-104.93-20.139-179.64-74.569-254.35-129v-128.5h1442v128.5c-84.414-64.115-168.82-128.23-264.37-123-95.542 5.23-202.2 79.804-295.41 132-93.206 52.196-172.95 82.014-280.39 104-70.505 14.428-152.93 25.484-230.93 25.687z"
          stroke="none"
          strokeWidth="0"
          fill={colorHex}
        ></path>
      )}
    </svg>
  );
}
