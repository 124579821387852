import * as React from "react";

export default function UndrawWorkingFromAnywhere({ className }) {
  return (
    <svg
      id="a379d9a4-f6fe-40d1-beba-a0916853bd02"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 938.75919 806.74836"
      className={className}
      aria-hidden="true"
    >
      <circle cx="75.36532" cy="147.52379" r="75.36532" fill="#ff6584" />
      <path
        d="M1004.987,679.01278a136.64937,136.64937,0,0,1-6.38326,37.7743c-.08893.28379-.18213.56334-.2753.84715H974.50666c.02541-.25416.05085-.538.07627-.84715,1.5884-18.26028,10.74606-129.39752-.20334-148.4033C975.33687,569.9256,1006.83381,620.99164,1004.987,679.01278Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#e6e6e6"
      />
      <path
        d="M1003.19956,716.78708c-.19909.28379-.40664.56758-.61842.84715H984.71056c.13555-.24144.29227-.52523.4744-.84715,2.95233-5.32856,11.69063-21.25916,19.80207-37.7743,8.71716-17.74774,16.71423-36.169,16.04074-42.836C1021.23531,637.68045,1027.267,683.51959,1003.19956,716.78708Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#e6e6e6"
      />
      <rect
        x="253.75919"
        y="669.38465"
        width="685"
        height="2.24072"
        fill="#e6e6e6"
      />
      <path
        d="M822.84364,785.9808l3.32758-27.45259,94.00433-24.125-10.81466-32.444c7.53317-21.60748,20.759-32.2,44.92242-21.62931l41.56416,25.82015a26.533,26.533,0,0,1,12.49331,23.97036v0a26.51238,26.51238,0,0,1-15.09493,22.535C946.71544,774.71149,887.41112,783.15126,822.84364,785.9808Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#ffb8b8"
      />
      <path
        d="M828.66691,794.29977l-29.11638-4.15948-27.23329,5.55781a11.76263,11.76263,0,0,1-14.1045-11.0354v0a11.76267,11.76267,0,0,1,4.67429-9.88433l42.67473-32.15219A19.2662,19.2662,0,0,1,830.519,744.136l10.62634,10.23277c-7.60426,4.71745-13.25855,11.79578-16.63793,21.62931Q835.46964,782.0244,828.66691,794.29977Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#2f2e41"
      />
      <circle cx="725.85545" cy="432.52561" r="26.92727" fill="#ffb8b8" />
      <path
        d="M898.54623,532.25232l-60.31251,25.37284c4.90182-24.6117,7.3447-47.03168,0-60.72845l38.6832-4.57544C874.99682,502.84723,884.78379,516.94354,898.54623,532.25232Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#ffb8b8"
      />
      <path
        d="M796.22294,785.14891l-22.46121-1.6638c-13.76-57.37964-20.805-111.61522-17.73-161.1123a27.99719,27.99719,0,0,1,28.55155-26.31008h0a27.95972,27.95972,0,0,1,18.63912,7.698l29.04629,27.64082a119.53382,119.53382,0,0,1,29.4304,43.81895c8.2263,21.54543,5.27317,33.62388-14.36545,31.57272a48.46037,48.46037,0,0,1-31.55432-16.7762l-21.22016-24.66128Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#ffb8b8"
      />
      <path
        d="M778.829,853.33321h0a19.21665,19.21665,0,0,1-16.93815-25.37662l4.38389-12.8594,3.32759-32.444c5.12583-10.45979,12.38523-8.895,20.79742-.8319,5.348-.52506,6.49594-5.87278,5.82327-13.31035l16.86453,22.70225a13.53384,13.53384,0,0,1,1.45034,13.68454l-16.97287,37.23082A19.21666,19.21666,0,0,1,778.829,853.33321Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#2f2e41"
      />
      <path
        d="M991.30271,703.20708c-30.78017,17.46983-40.42721,17.97672-71.12716,30.36423l-26.6207-14.97414c-31.26416,11.56034-53.19528-4.287-76.95044-27.86854,15.54546-25.89253,8.67013-59.53966,18.30173-59.06466l115.21769,36.1875,4.99138,12.47846Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#2f2e41"
      />
      <path
        d="M954.28331,676.17044c-40.6823,17.60906-84.48565-1.03464-121.87286-42.01078l-24.75581-99.97937a8.344,8.344,0,0,1,6.42773-10.32486l28.72679-5.74536,14.14224,22.46121,28.28449-24.9569,30.03793,9.8201A23.19158,23.19158,0,0,1,930.9073,543.455Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#60a5fa"
      />
      <path
        d="M889.66449,760.92835h0a14.69073,14.69073,0,0,1-5.97562-23.22858l19.01689-22.43017,13.31034,7.48707-6.854,27.943A14.69072,14.69072,0,0,1,889.66449,760.92835Z"
        transform="translate(-130.62041 -46.62582)"
        opacity="0.2"
      />
      <path
        d="M889.66449,757.60077l0,0a14.69073,14.69073,0,0,1-5.97562-23.22858L902.70572,711.942l13.31034,7.48707-6.854,27.943A14.69073,14.69073,0,0,1,889.66449,757.60077Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#ffb8b8"
      />
      <path
        d="M919.34365,726.08424,899.37813,712.7739l29.11638-77.3664L917.67986,526.429l9.86544,6.1659a46.09758,46.09758,0,0,1,20.72563,29.82761l15.99514,77.97633C957.26725,676.34074,945.60181,707.96424,919.34365,726.08424Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#60a5fa"
      />
      <polygon
        points="776.661 555.922 786.644 605.836 778.433 635.028 776.661 555.922"
        opacity="0.2"
      />
      <path
        d="M830.14152,476.84239s-15.517-50.78312,31.90729-40.13255c7.3558,1.652,13.71081,5.92791,18.76625,11.52072a4.56981,4.56981,0,0,0,3.57247,1.45942c7.57575,0,12.25524,27.07165-2.89626,38.97641,0,0,3.0916-16.01961-2.528-21.47812a12.0563,12.0563,0,0,1-2.5013-3.22058c-3.65758-7.37809-15.95243-9.79984-24.432-9.17173-4.329.32067-4.70747,7.8684-9.4997,6.81418C831.62612,459.21134,828.1454,471.843,830.14152,476.84239Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#2f2e41"
      />
      <path
        d="M877.87455,552.21622l-.3135,2.29926-1.93792,14.18773-3.17365,23.23436-.79455,5.81721a1.85307,1.85307,0,0,1-1.83645,1.60276H848.65539a1.85358,1.85358,0,0,1-1.83647-1.59825l-.80823-5.82172-3.22718-23.23436-1.97213-14.19807-.31689-2.2845a1.85321,1.85321,0,0,1,1.83531-2.10888h33.70831a1.85417,1.85417,0,0,1,1.83644,2.10446Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#e6e6e6"
      />
      <path
        d="M713.94258,497.33038H743.294a1.85356,1.85356,0,0,1,1.85356,1.85356v2.44864a1.85351,1.85351,0,0,1-1.85351,1.85351H713.94263a1.85356,1.85356,0,0,1-1.85356-1.85356V499.1839A1.85351,1.85351,0,0,1,713.94258,497.33038Z"
        fill="#3f3d56"
      />
      <path
        d="M880.52618,553.18977a196.18942,196.18942,0,0,1-42.57495,0,1.85342,1.85342,0,0,1-1.85346-1.85341h0v-2.44879a1.85343,1.85343,0,0,1,1.85346-1.85341,174.44189,174.44189,0,0,0,42.57495,0,1.85344,1.85344,0,0,1,1.85341,1.85341v2.44879A1.85344,1.85344,0,0,1,880.52618,553.18977Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#3f3d56"
      />
      <circle cx="727.75919" cy="527.00841" r="8" fill="#fff" />
      <path
        d="M878.94621,590.46462v0A14.69073,14.69073,0,0,1,860.9047,606.269L832.31055,599.404l.68373-15.25628,28.0328-6.47708A14.69073,14.69073,0,0,1,878.94621,590.46462Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#ffb8b8"
      />
      <path
        d="M840.31347,602.13164l-77.1686,1.92921a19.16715,19.16715,0,0,1-19.45354-21.8718v0a19.16712,19.16712,0,0,1,5.88033-11.28657l36.33349-33.9894a48.51883,48.51883,0,0,1,24.20448-12.25587l12.734-2.38762,7.48707,56.569-43.25863-4.99138,50.7457,5.82328Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#60a5fa"
      />
      <path
        d="M738.65069,768.1268H541.64343a6.60576,6.60576,0,0,1-6.60576-6.60576h0q107.12263-12.44971,210.21878,0h0A6.60576,6.60576,0,0,1,738.65069,768.1268Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#2f2e41"
      />
      <path
        d="M745.25645,761.90961,535.03767,761.521l24.36362-40.99461.11657-.19429V629.98469a8.34976,8.34976,0,0,1,8.35046-8.35046H711.26008a8.34976,8.34976,0,0,1,8.35046,8.35046v90.96916Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#3f3d56"
      />
      <path
        d="M567.67792,627.46285a2.72307,2.72307,0,0,0-2.72,2.72v82.37778a2.7232,2.7232,0,0,0,2.72,2.72H712.6162a2.72333,2.72333,0,0,0,2.72-2.72V630.18287a2.7232,2.7232,0,0,0-2.72-2.72Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#fff"
      />
      <path
        d="M568.63047,726.16076a1.169,1.169,0,0,0-1.0591.67849l-7.50719,16.32013a1.16568,1.16568,0,0,0,1.05891,1.653H718.99531a1.1656,1.1656,0,0,0,1.0424-1.68712l-8.16006-16.32012a1.15981,1.15981,0,0,0-1.0424-.64434Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#2f2e41"
      />
      <circle cx="508.94379" cy="577.72843" r="1.74859" fill="#fff" />
      <path
        d="M624.04664,747.92093a1.16751,1.16751,0,0,0-1.1255.86253l-1.8831,6.99434a1.16562,1.16562,0,0,0,1.1255,1.46892H657.976a1.16545,1.16545,0,0,0,1.10121-1.54709l-2.421-6.99434a1.16634,1.16634,0,0,0-1.10159-.78436Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#2f2e41"
      />
      <polygon
        points="588.99 672.346 588.99 673.901 428.781 673.901 428.901 673.706 428.901 672.346 588.99 672.346"
        fill="#2f2e41"
      />
      <path
        d="M491.52194,667.13261a175.14542,175.14542,0,0,0,8.18151,48.41582c.114.36373.23344.722.35286,1.0858H530.589c-.03257-.32576-.06518-.68949-.09775-1.0858-2.03588-23.40445-13.77337-165.85054.26061-190.21049C529.52494,527.3141,489.1549,592.76613,491.52194,667.13261Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#e6e6e6"
      />
      <path
        d="M493.813,715.54843c.25518.36373.5212.72747.79265,1.0858h22.905c-.17373-.30946-.3746-.67319-.608-1.0858-3.784-6.82969-14.984-27.24816-25.38058-48.41582-11.17289-22.74753-21.42285-46.35825-20.55963-54.90349C470.6963,614.15641,462.96541,672.909,493.813,715.54843Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#e6e6e6"
      />
      <path
        d="M545.473,221.78479l12.41349-9.92842c-9.64348-1.06394-13.60582,4.19541-15.22744,8.35823-7.53388-3.12835-15.73541.97151-15.73541.97151l24.83709,9.01675A18.79465,18.79465,0,0,0,545.473,221.78479Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#3f3d56"
      />
      <path
        d="M919.35713,170.6795l12.41349-9.92841c-9.64349-1.06394-13.60582,4.19541-15.22744,8.35823-7.53389-3.12835-15.73541.97151-15.73541.97151l24.83709,9.01674A18.79483,18.79483,0,0,0,919.35713,170.6795Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#3f3d56"
      />
      <path
        d="M847.87608,302.43457l12.41348-9.92842c-9.64348-1.06394-13.60581,4.19541-15.22743,8.35823-7.53389-3.12835-15.73542.97151-15.73542.97151l24.8371,9.01674A18.79481,18.79481,0,0,0,847.87608,302.43457Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#3f3d56"
      />
      <ellipse
        cx="320.01291"
        cy="272.58054"
        rx="112.0821"
        ry="228.69277"
        transform="translate(-173.90826 14.4814) rotate(-10.22048)"
        fill="#e6e6e6"
      />
      <path
        d="M301.65642,229.77517q14.78775,40.21121,26.31876,81.51807,11.509,41.26774,19.68957,83.36863,8.18007,42.08713,12.9707,84.73824,4.767,42.57644,6.1255,85.44331,1.35042,42.82083-.7457,85.65769-.258,5.24814-.56839,10.49347c-.21056,3.58181,5.36184,3.56959,5.57167,0q2.52606-42.97206,1.585-86.05062-.94624-42.83215-5.3194-85.49687-4.38378-42.78927-12.14609-85.146-7.767-42.33823-18.91259-83.9628-11.13864-41.57793-25.61467-82.16468-1.76623-4.94872-3.58171-9.87963c-1.22732-3.33733-6.6142-1.89487-5.37265,1.48117Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#3f3d56"
      />
      <path
        d="M340.048,335.23028l24.68456-54.8093L371.69,264.9728a2.87134,2.87134,0,0,0-.99943-3.81155,2.80754,2.80754,0,0,0-3.81155.99942L342.19442,316.97,335.237,332.41815a2.87134,2.87134,0,0,0,.99943,3.81155,2.80754,2.80754,0,0,0,3.81155-.99942Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#3f3d56"
      />
      <path
        d="M345.50228,372.68041l-56.28535-21.10324-15.86421-5.948a2.80924,2.80924,0,0,0-3.42691,1.94574,2.84665,2.84665,0,0,0,1.94574,3.42691L328.15689,372.105l15.86421,5.948a2.80924,2.80924,0,0,0,3.42691-1.94574,2.84663,2.84663,0,0,0-1.94573-3.42691Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#3f3d56"
      />
      <path
        d="M301.67218,671.62582s67.78868-22.28669,130.93431,0"
        transform="translate(-130.62041 -46.62582)"
        fill="#e6e6e6"
      />
      <path
        d="M604.99788,661.06645c13.43289-.13986,26.86407-.38,40.29636-.5625q6.717-.09126,13.43431-.16c3.85856-.04017,3.86843-6.04028,0-6-13.43289.13986-26.86407.38-40.29636.5625q-6.717.09124-13.43431.16c-3.85856.04017-3.86843,6.04027,0,6Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#60a5fa"
      />
      <path
        d="M604.99788,675.06645l54.29031-.5625,15.44036-.16c3.85858-.04,3.86842-6.04008,0-6l-54.29031.5625-15.44036.16c-3.85858.04-3.86842,6.04008,0,6Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#60a5fa"
      />
      <path
        d="M604.99788,689.06645l54.29031-.5625,15.44036-.16c3.85858-.04,3.86842-6.04008,0-6l-54.29031.5625-15.44036.16c-3.85858.04-3.86842,6.04008,0,6Z"
        transform="translate(-130.62041 -46.62582)"
        fill="#60a5fa"
      />
    </svg>
  );
}
