import * as React from "react";

export default function UndrawDevices({ className }) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 57.717 57.717"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden="true"
    >
      <g transform="translate(-5.6928e-7 .18848)">
        <path
          d="m57.585 28.67a28.726 28.726 0 0 1-28.569 28.726 28.726 28.726 0 0 1-28.882-28.411 28.726 28.726 0 0 1 28.253-29.037 28.726 28.726 0 0 1 29.191 28.094"
          fill="#77b3fd"
          opacity=".10"
        />
      </g>
    </svg>
  );
}
